//
// _topbar.scss
//
#bg {
  position: fixed;
  background-color: #1f2e3d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  transform: scale(1.0); 
  &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../images/background.png");
      background-position: 0% 0%;
      background-size: cover;
      background-repeat: round;
      // background-repeat: no-repeat;
  }
}

body {
  background-color: transparent!important;
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  // z-index: 1000;
  z-index: 1;
  // background-color: $header-bg;
  background-color: rgb(15 15 18 / 70%);
  transition: all 0.1s ease-out;

  &.topbar-shadow {
    box-shadow: $box-shadow;
  }

  @media (min-width: 768px) {
    // left: $vertical-menu-width;
  }

  
  @media (max-width: 768px) {
    background-color: transparent;
    // left: $vertical-menu-width;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 $grid-gutter-width 0 calc(#{$grid-gutter-width} / 2)
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  @media (max-width: 767.98px) {
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 calc(#{$grid-gutter-width} / 2);
  }

  .topbar-head-dropdown {
    .dropdown-menu.show {
      top: 13px !important;
      overflow: hidden;
    }

    .notification-actions {
      display: none;
      position: absolute;
      background-color: $dropdown-bg;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 7px;
      border-top: 1px solid var(--#{$prefix}border-color);
    }
  }

  .btn-topbar {
    height: 38px;
    width: 42px;

    @media (max-width: 360px) {
      height: 20px;
      width: 20px;
    }
  }

  .user-name-text {
    color: var(--#{$prefix}body-color);
  }
}

// [data-bs-theme="dark"][data-topbar="light"] {
//     .navbar-header {
//         .horizontal-logo {
//             .logo-dark {
//                 .logo-lg {
//                     display: block;
//                 }
//                 .logo-sm {
//                     display: none;
//                 }
//             }

//             .logo-light {
//                 .logo-sm {
//                     display: none;
//                 }
//                 .logo-lg {
//                     display: none;
//                 }
//             }
//         }
//     }
// }

/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 30px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    width: 250px;
  }

  span.search-widget-icon {
    position: absolute;
    z-index: 10;
    font-size: 18px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }

  .search-widget-icon-close {
    right: 7px;
    left: auto !important;
  }

  @media (max-width: 1023.99px) {
    padding-left: calc(#{$grid-gutter-width} / 2);
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: $dropdown-color;
    }
  }
}

@media (max-width: 767.98px) {
  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

// newly added for responsive by ak
.header-item-responsive {
  height: $header-height;
  display: flex;
  align-items: center;

  @media (max-width: 767.98px) {
    height: 50px;
  }
}

.main-menu-dropdown-head {
  height: 90px;
  display: flex;
  align-items: center;
}
.main-menu-dropdown-head-sm{
  margin-top: 5px;
  height: 45px;
  display: flex;
  align-items: center;
}


.header-item {
  height: $header-height;
  display: flex;
  align-items: center;
}

.header-profile-user {
  height: 36px;
  width: 36px;
}

.topbar-badge-sm {
  right: 0;
  top: 7px !important;
}

.topbar-badge {
  right: -4px;
  top: 12px !important;
}

.topbar-user {
  @media (min-width: 768px) {
    background-color: $topbar-user-bg;
  }

  .dropdown-menu {
    top: 6px !important;
  }
}

.notification-item {
  padding: 0.75rem 1rem;
  white-space: inherit;
  position: relative;

  .form-check-input {
    position: relative;
    z-index: 2;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: var(--#{$prefix}dropdown-link-color);

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .la-expand::before {
      content: "\f066";
    }
  }
}

// Dark mode enable
[data-bs-theme="dark"] {
  .light-dark-mode {
    .la-moon::before {
      content: "\f185";
    }
  }
}

[data-topbar="dark"] {
  #page-topbar {
    background-color: var(--#{$prefix}header-bg-dark);
    border-color: var(--#{$prefix}header-bg-dark);
  }

  .navbar-header {
    .btn-topbar {
      color: $header-item-color-dark;

      &:hover,
      &:focus {
        background-color: rgba($white, 0.07);
        color: $white;
      }
    }
  }

  .topbar-user {
    @media (min-width: 767.99px) {
      background-color: $topbar-user-bg-dark;
    }

    .user-name-text {
      color: rgba($white, 0.85) !important;
    }

    .user-name-sub-text {
      color: $header-item-color-dark !important;
    }
  }

  .app-search {
    .form-control {
      background-color: rgba($white, 0.05);
      color: $primary;
    }

    span.search-widget-icon,
    input.form-control::-webkit-input-placeholder {
      color: rgba($primary, 0.5);
    }
  }

  .hamburger-icon {
    span {
      background-color: $gray-300;
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  #search-dropdown-reponsive {
    top: 54px !important;
  }
}

[data-layout="vertical"] {
  &[data-layout-style="detached"] {
    @media (min-width: 1024.1px) {
      #page-topbar {
        left: 0 !important;
        box-shadow: $box-shadow;
      }

      .horizontal-logo {
        display: inline-block;
        padding-left: 0;
      }

      .topnav-hamburger {
        visibility: hidden;
      }

      .layout-width {
        max-width: 95%;
        margin: 0 auto;
      }

      &[data-sidebar-size="sm"],
      &[data-sidebar-size="sm-hover"] {
        .navbar-brand-box {
          background-color: transparent !important;
          position: relative;
          width: auto;
          text-align: left;

          .logo-sm {
            display: none;
          }

          .logo-lg {
            display: block;
          }
        }
      }
    }

    &[data-topbar="dark"] {
      .horizontal-logo {
        .logo-dark {
          display: none;
        }

        .logo-light {
          display: block;
        }
      }
    }
  }
}

[data-layout="horizontal"] {
  #page-topbar {
    left: 0;
    border-bottom: 1px solid var(--#{$prefix}border-color);

    @media (min-width: 1024.1px) {
      &.topbar-shadow {
        box-shadow: none;
      }
    }
  }

  .page-content {
    @media (min-width: 1024.1px) {
      margin-top: $header-height;
    }

    padding: calc(45px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }

  &[data-layout-width="boxed"] {
    .page-content {
      @media (min-width: 1024.1px) {
        min-height: calc(100vh - #{$footer-height + $header-height});
      }
    }
  }

  .horizontal-logo {
    .logo-dark {
      .logo-lg {
        display: block;
      }
      .logo-sm {
        display: none;
      }
    }

    .logo-light {
      .logo-lg {
        display: none;
      }
      .logo-sm {
        display: none;
      }
    }
  }

  &[data-topbar="dark"] {
    .horizontal-logo {
      .logo-dark {
        .logo-lg {
          display: none;
        }
      }

      .logo-light {
        .logo-lg {
          display: block;
        }
      }
    }
  }
}
[data-bs-theme="dark"][data-layout="horizontal"][data-topbar="light"] {
  .horizontal-logo {
    .logo-dark {
      .logo-lg {
        display: none;
      }
      .logo-sm {
        display: none;
      }
    }

    .logo-light {
      .logo-lg {
        display: block;
      }
      .logo-sm {
        display: none;
      }
    }
  }
}

[data-layout="vertical"] {
  &[data-sidebar-size="sm"],
  &[data-sidebar-size="sm-hover"] {
    #page-topbar {
      @media (min-width: 768px) {
        // left: $vertical-menu-width-sm;
      }
    }
  }

  &[data-sidebar-size="md"] {
    #page-topbar {
      @media (min-width: 768px) {
        // left: $vertical-menu-width-md;
      }
    }
  }
}
