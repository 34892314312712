//
// forms.scss
//

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left /*rtl: right*/;
  }
}

.custom-input-placeholder::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #00f600 !important;
}
.custom-input-placeholder:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #00f600 !important;
  opacity: 1;
}
.custom-input-placeholder::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #00f600 !important;
  opacity: 1;
}
.custom-input-placeholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #00f600 !important;
}
.custom-input-placeholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #00f600 !important;
}

.custom-input-placeholder::placeholder {
  /* Most modern browsers support this now. */
  color: #00f600 !important;
}
